@import "./variables.less";

.firebaseui-idp-button {
  border-radius: 20px !important;
}

.mdl-card {
  border-radius: 20px !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.mdl-button--colored {
  background-color: @color-brand-primary !important;
  color: @color-white !important;
  border-radius: 20px !important;
}

.mdl-button--primary {
  color: @color-brand-primary !important;
  border-radius: 20px !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: @color-brand-primary !important;
}

.firebaseui-link {
  color: @color-brand-primary !important;
}

.firebaseui-subtitle, .firebaseui-title {
  color: @color-black !important;
}

.mdl-progress.mdl-progress--indeterminate>.bar1, .mdl-progress.mdl-progress__indeterminate>.bar1 {
  color: @color-brand-primary !important;
  background-color: @color-brand-primary !important;
}

.mdl-progress.mdl-progress--indeterminate>.bar3, .mdl-progress.mdl-progress__indeterminate>.bar3 {
  background-color: @color-brand-primary !important;
}

.mdl-progress>.bufferbar {
  background-image: linear-gradient(to right,rgba(255,255,255,.7),rgba(255,255,255,.7)),linear-gradient(to right,@color-brand-primary,@color-brand-primary) !important;
}

.mdl-progress>.auxbar {
  background-image: linear-gradient(to right,rgba(255,255,255,.7),rgba(255,255,255,.7)),linear-gradient(to right,@color-brand-primary,@color-brand-primary) !important;
}

.mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate)>.auxbar, .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate)>.auxbar {
  background-image: linear-gradient(to right,rgba(255,255,255,.7),rgba(255,255,255,.7)),linear-gradient(to right,@color-brand-primary,@color-brand-primary) !important;
}

.progressbar {
  background-color: @color-brand-primary !important;
}
