@import "../../styles/variables.less";

.horizontalLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin: auto;

  .image {
    max-width: 400px;
  }
}

.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider {
  height: 1px;
  width: 260px;
  background-color: #8D99AE;
  margin-top: 30px;
  margin-bottom: 12px;
}

.desc {
  max-width: 300px;
  text-align: center;

  :global {
    a {
      color: @color-brand-primary !important;
    }
  }
}

.verticalLayout {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    max-width: 300px;
    margin-top: 40px;
  }
}

.firebaseAuthContainer {
  display: flex;
  min-width: 360px;
  justify-content: center;
  align-items: center;
}

.supaAuthContainer {
  display: flex;
  min-width: 360px;
  justify-content: center;
  align-items: center;
  > * {
    width: 100%;
  }
}

.loadingSpinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid @color-brand-primary;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loginForm {
  width: 332px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--color-bg-card);
  color: var(--color-text-base);
  padding: 28px;
}

.title {
  font-family: Bubblegum Sans;
  font-size: 18px;
  margin-bottom: 10px;
}

.name {
  color: @color-brand-primary;
}