.action-icon {
  color: var(--color-action-icon);
  cursor: pointer;
  transition: color 0.1s ease-in-out;

  &.disabled {
    cursor: not-allowed;
    color: var(--color-text-disabled);
  }

  &:not(.disabled):hover {
    color: var(--color-brand-primary);
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
