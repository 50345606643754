@import "./variables.less";

/* 重置 antd 样式 */
.ant-anchor-wrapper-horizontal::before {
  display: none !important;
}

.ant-anchor-ink {
  display: none;
}

.ant-anchor-link-title {
  &:hover {
    color: @color-nav-active !important;
  }
}

.ant-anchor-link-title-active {
  color: @color-nav-active !important;
}

// 移除 antd 中不满意的 outline 效果
.ant-btn:not(:disabled):focus-visible,
.ant-input-affix-wrapper:focus-within {
  outline: none !important;
}

// 定制 default btn 具备 hover 效果
.ant-btn-default:hover {
  border-color: @color-brand-primary !important;
  background: @color-brand-primary !important;
  color: @color-white !important;
}

.ant-dropdown-menu-item {
  padding: 8px 14px !important;
}

.ant-drawer-body {
  padding: 10px 24px 24px 24px !important;
}

.ant-input:not(:read-only) {
  color: var(--color-text-base);
  background-color: var(--color-bg-input);
}

.ant-input::placeholder {
  color: var(--color-text-disabled);
}

.ant-list-item {
  color: var(--color-text-base) !important;
}

// 本项目不使用 antd title 组件
// 因此容易被遮住，并增大点击区域
.ant-modal-close {
  z-index: 10 !important;
  width: 32px !important;
  height: 32px !important;

  svg {
    width: 20px;
    height: 20px;
  }
}
.ant-modal {
  color: var(--color-text-base) !important;
}

/* 课程树形控件 */
.ant-tree {
  background-color: transparent;
}

.ant-tree-title {
  font-size: 18px;
  font-family: @font-family-inder;
}

.ant-form-item-control-input-content {
  input {
    border-radius: 16px;
    font-family: @font-family-inder;
  }
}
