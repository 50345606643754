@import "@/styles/variables.less";

.pageContainer {
  min-height: 100vh;
  padding: @padding-page;
  background: linear-gradient(180deg, @color-bg-gray 0%, rgba(237, 242, 244, 0) 100%);
  color: var(--color-text-base);
}

:global(body[data-theme="dark"]) .pageContainer {
  background: var(--color-bg-page);
}
