@import "../../styles/variables.less";

.logoWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.codeKidLogo {
  width: 45px;
  height: 45px;
}

.logoName {
  margin-left: 5px;
  margin-top: 6px;
  font-family: @font-family-bubblegum-sans;
  font-size: @font-size-h1;
  color: @color-brand-primary;
}

@media screen and (max-width: 600px){
  .codeKidLogo {
    width: 36px;
    height: 36px;
  }

  .logoName {
    font-size: 28px;
  }
}
