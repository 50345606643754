@import "./styles/variables.less";
@import "./styles/global.less";
@import "./styles/reset.less";
@import "./styles/resetAntd.less";
@import "./styles/fonts.less";
@import "./styles/resetFirebase.less";

.App,
body,
html {
  font-family: @font-family-inder;
  color: var(--color-text-base);
  background: var(--color-bg-page);
}

h1 {
  font-family: @font-family-bubblegum-sans;
}

code {
  font-family: @font-family-mono;
}

a {
  color: @color-brand-primary;
  &:hover {
    color: @color-brand-primary-light;
  }
}

.codeKidLogo {
  width: 255px;
}

// 一级 title 样式
.codeKidTitle {
  font-family: Bubblegum Sans;
  font-size: 40px;
}
