@import "./variables.less";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clearfix {
  zoom: 1;

  &:after,
  &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
    overflow: hidden;
  }
}

a {
  color: var(--color-text-base) !important;
}

.ant-tooltip {
  font-family: @font-family-base !important;
  font-size: @font-size-base !important;
}

.cm-theme * {
  font-family: @font-family-mono !important;
}

.ant-btn-default:disabled {
  border-color: #8D99AE;
}

/** --- reset for firebase starts --- **/

.firebaseui-tos {
  color: var(--color-text-base);
}

/** --- reset for firebase ends --- **/
